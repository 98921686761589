import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  useCameras,
  useDisableZoom,
  useError,
  useMonitor,
  useTimer,
} from "../../../../hooks";
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  Stack,
  Typography,
} from "@mui/material";
import { SessionState } from "@binah/web-sdk";
import AwaitCameraPermissions from "../AwaitCameraPermissions";
import { HealthCheckQuestionnaireData } from "../../../../../../services/core-api-adapter";
import { useTranslation } from "react-i18next";
import { trackEvent } from "@/services/analytics-adapter";
import { convertPxToRem } from "@/utils";

function useIsScannerMounted() {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);
  return () => isMounted.current;
}

interface ScannerProps {
  onError: (error: any) => void;
  onScanReady: () => void;
  onScanComplete: (vitalSigns: any) => void;
  onStartMeasuring: boolean;
  measurementDuration: number;
  onBackButton: (error: any) => void;
  healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  onVitalSignsChanged?: (vitalSigns: any) => void;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; showLabel: boolean }
) {
  const PROGRESS_SIZE = "23rem";

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "white",
        }}
        size={PROGRESS_SIZE}
        thickness={1}
        value={100}
      />

      <CircularProgress
        variant={props.variant || "determinate"}
        disableShrink
        sx={{
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        value={props.value}
        color={props.color}
        size={PROGRESS_SIZE}
        thickness={1.2}
      />
      {props.showLabel && (
        <Box
          sx={{
            top: 0,
            left: "50%",
            transform: "translate(-50%, -40%)",
            borderRadius: 5,
            width: (theme) => theme.spacing(8),
            background: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            py: 1,
            position: "absolute",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: () =>
                props.color === "success" ? "success.main" : "primary.main",
            }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default function Scanner({
  onVitalSignsChanged,
  onError,
  onStartMeasuring,
  measurementDuration,
  onScanReady,
  onScanComplete,
  healthCheckQuestionnaireData,
  onBackButton,
}: ScannerProps) {
  const { t } = useTranslation();
  const cameras = useCameras();
  const [cameraId, setCameraId] = useState<string>("");
  const [videoStreamTrack, setVideoStreamTrack] = useState<any>();
  const licenseKey = import.meta.env.VITE_APP_BINAH_AI_SDK_LICENSE_KEY || "";
  const videoRef = useRef<HTMLVideoElement>(null);
  const [measurementProgress, setMeasurementProgress] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");

  const mounted = useIsScannerMounted();
  useDisableZoom();

  const { sessionState, vitalSigns, error, info, isScanComplete } = useMonitor(
    videoRef as any,
    cameraId,
    measurementDuration,
    licenseKey,
    onStartMeasuring,
    healthCheckQuestionnaireData
  );

  const errorMessage = useError(error);
  const isMeasuring = useCallback(
    () => sessionState === SessionState.MEASURING,
    [sessionState]
  );

  useEffect(() => {
    if (cameras?.length) {
      setCameraId(cameras[0].deviceId);
    }
  }, [cameras]);

  useEffect(() => {
    const videoElement = document.getElementById("video") as any;
    const stream = videoElement.srcObject;

    if (stream) {
      const tracks = stream.getTracks();
      setVideoStreamTrack(tracks[0]);
    }

    return () => {
      if (!mounted() && videoStreamTrack) {
        videoStreamTrack.stop();
      }
    };
  }, [mounted]);

  const seconds = useTimer(onStartMeasuring, measurementDuration);

  function getPercentage() {
    const computedPercentage = seconds / measurementDuration;
    const percentage = Math.floor(computedPercentage * 100);
    return percentage;
  }

  useEffect(() => {
    if (getPercentage() <= 100) {
      setMeasurementProgress(getPercentage());
    }
  }, [seconds]);

  useEffect(() => {
    if (error) {
      onError(error);
    }

    if (info) {
      if (info.type <= 0) {
        setAlertMessage("");
      }

      if (info.message) {
        trackEvent({
          event: "action.healthCheckFaceScanWarningEncountered",
          warningMessage: info.message,
          source: "Health check",
        });
        setAlertMessage(info.message);
      }
    }
  }, [error, info]);

  useEffect(() => {
    if (sessionState === SessionState.ACTIVE) {
      onScanReady();
    }

    if (isMeasuring() && onVitalSignsChanged) {
      onVitalSignsChanged(vitalSigns);
    }

    if (isScanComplete === true && Object.keys(vitalSigns || {})?.length) {
      onScanComplete(vitalSigns);
    }
  }, [vitalSigns, sessionState, errorMessage, measurementDuration]);

  return (
    <>
      {!sessionState && (
        <Stack
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 4,
          }}
        >
          <AwaitCameraPermissions onBackButtonClick={onBackButton} />
        </Stack>
      )}
      <Stack
        sx={{
          height: "100%",
          top: 0,
          zIndex: 0,
          backgroundColor: "neutral.dark",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          <Stack
            sx={{
              position: "absolute",
              zIndex: 3,
              top: "20%",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
            spacing={2}
          >
            <CircularProgressWithLabel
              value={measurementProgress}
              showLabel={onStartMeasuring}
              variant="determinate"
              color={measurementProgress === 100 ? "success" : "primary"}
            />
            <Stack color="neutral.contrastText">
              <Typography variant="h2" textAlign="center" color="inherit">
                {alertMessage.match("Warning")
                  ? t("BinahScanFlow.measurenow.state.SCAN_IN_PROGRESS.title")
                  : alertMessage}
              </Typography>
            </Stack>
          </Stack>
          <video
            ref={videoRef}
            id="video"
            muted={true}
            playsInline={true}
            style={{
              transform: "scaleX(-1)",
              objectFit: "cover",
              borderRadius: "50%",
              width: convertPxToRem(368),
              height: convertPxToRem(368),
              position: "absolute",
              zIndex: 1,
              top: "20%",
            }}
          />
        </>
      </Stack>
    </>
  );
}

export const MemoizedScanner = memo(Scanner);
